var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "fast-search" },
    [
      _c("CategoryTitle", { attrs: { category: _vm.category } }),
      _c(
        "div",
        { staticClass: "text-body-1 mb-2" },
        [
          _vm._v(" " + _vm._s(_vm.$t("fastSearch.searchSummary")) + " "),
          _vm._l(_vm.query, function(q, index) {
            return _c(
              "span",
              { key: index },
              [
                _c(
                  "router-link",
                  { attrs: { to: { name: "Search", query: { q: q } } } },
                  [_vm._v(_vm._s(q))]
                ),
                _vm._v(
                  _vm._s(
                    index == _vm.query.length - 2
                      ? _vm.$t("fastSearch.and")
                      : index == _vm.query.length - 1
                      ? "."
                      : ", "
                  )
                )
              ],
              1
            )
          })
        ],
        2
      ),
      _vm._l(_vm.query, function(q, index) {
        return _c(
          "div",
          { key: index, staticClass: "search-result" },
          [
            _c(
              "div",
              {
                staticClass:
                  "searched-word-header d-flex align-center flex-column flex-sm-row mb-5 mb-sm-2"
              },
              [
                _c("div", { staticClass: "searched-word-title" }, [
                  _c("h3", {
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("fastSearch.searchResult", [q]))
                    }
                  })
                ]),
                _c("v-spacer"),
                _c(
                  "div",
                  [
                    _c(
                      "router-link",
                      { attrs: { to: { name: "Search", query: { q: q } } } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("fastSearch.showAllProducts")) +
                            " "
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("ProductListSlider", {
              attrs: {
                query: q,
                paginationClass: "swiper-pagination-" + index,
                cols: "auto",
                sm: "2",
                md: "2",
                lg: "3",
                xl: "4"
              }
            })
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }